import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

class CimsNotice {
    
    /**
     * 생성자
     */
     constructor() {
        this.systemCode = 'CIM';
        this.boardCategory = 'CIMCM002';
        this.currentPage = 1;
        this.listSize = 2147483647; // [주의] 쿼리 수정 하면 안됨. top size = int.max
        this.gridTopSize = 15;      // [주의] 쿼리 상의 top size 와 다름. 이것은 그리드용 top size.
    }

    /**
     * cims 공지사항 목록 조회
     * @param {Any} parameters 
     * @returns 
     */
    getBoardList(parameters) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
            request.methodId = 'GetKRTBoardContentList';
            request.parameters.dic = parameters

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }
}
export const cimsNotice = new CimsNotice();