<template>
    <div class="admin-content">
		<h3>공지사항</h3>
		<div class="flex-wrap right">
			<div class="ml10">
				<div class="btn-wrap">
					<button class="btn-box" @click="methods.getCimsNotices" >조회</button>
					<button class="btn-box green"  @click="methods.moveDtls">신규</button>
				</div>
			</div>
            <div>						
				<div class="sch-wrap3">
					<div>
						<!-- 검색 조건 -->
                        <div class="select-box">
                            <select id="selectSearchType" v-model="state.parameter.SearchType">
                                <option value="1">제목</option>
                                <option value="2">내용</option>
                            </select>
                        </div>
					</div>
					<div>
						<div>
                            <input class="input-text mr10" type="text" v-model="state.parameter.SearchText" @keyup.enter="methods.getCimsNotices" />
						</div>
					</div>
				</div>
			</div>
        </div>
        
        <!-- 총 개수 -->
		<div class="total-box">
			<p>Total <strong>{{state.dataTable.rowCount}}</strong></p>
		</div>

        <DataTable 
            class="p-datatable-sm" 
            columnResizeMode="fit"
            scrollHeight="497px" 
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :value="state.dataTable.cimsNoticeItems"
            :paginator="true"
            :rowHover="true" 
            :rows="cimsNotice.gridTopSize"
            :resizableColumns="true"
            @row-click="eventHandler.dataTable.onRowClick">

            <template #empty>
                데이터가 없습니다.
            </template>
            
            <Column field="Sort" header="번호" :style="{'text-align':'center', 'width' : '90px'}" headerClass="tc"></Column>
            <Column field="Title" header="제목" :exportable="true" headerClass="tc"></Column>
            <Column field="FileCnt" header="첨부" :exportable="true" :style="{'text-align':'center', 'width' : '90px'}" headerClass="tc">
                <template #body="slotProps">
                    <i v-if="slotProps.data.FileCnt > 0" class="fa fa-save"><span class="sr-only"></span></i>
                </template>
            </Column>
            <Column field="CreateDate" header="작성일" style="text-align:center; width:200px;" headerClass="tc"></Column>
            <Column field="Hit" header="조회수" style="text-align:center; width:90px;" headerClass="tc"></Column>
        </DataTable>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { cimsNotice } from '@/modules/cims/CIM_CM0120R.js';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import router from '@/router'
export default {
    components: {},
    setup() {
        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // state
        const state = reactive({
            dataTable: {
                // 공지사항 정보
                cimsNoticeItems: null,

                // 행 개수
                rowCount : 0,
            },
            parameter: {
                // 시스템코드
                SystCode: cimsNotice.systemCode,

                // 보드 카테고리
                BoardCategory: cimsNotice.boardCategory,

                // 제목
                Title: null,

                // 내용
                Content: null,

                // 검색어
                SearchText: null,       
                
                // 검색유형 1 : 제목, 2 : 내용
                SearchType: "1",   
                
                // 사용여부
                UseYn: "Y",

                // 사용자번호
                UserNo: null,
                
                // 코드값
                CodeVal: null,

                // 그룹번호
                GroupNo: "0",

                // 현재 페이지
                CurPage: "1",

                // 목록 크기
                ListSize: cimsNotice.listSize,

                // ToDo 김도연
                ExcludeCodeVal: null,

                // ToDo 김도연
                IsContainExpireContens: null
            },
        });

        // 메서드
        const methods = {
            // cims 공지사항 조회
            getCimsNotices: () => {
                
                // 스토어 값 변경
                if (store.getters.isLoading == false)
                store.commit("setLoadingStatus", true);

                state.parameter.Title = state.parameter.SearchType === "1" ? state.parameter.SearchText : null;
                state.parameter.Content = state.parameter.SearchType === "2" ? state.parameter.SearchText : null;

                cimsNotice.getBoardList(JSON.stringify(state.parameter)).then(result => 
                {
                    state.dataTable.cimsNoticeItems = result;
                    if(!!state.dataTable.cimsNoticeItems) state.dataTable.rowCount = state.dataTable.cimsNoticeItems.length;
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },
            // 신규 등록
            moveDtls: function () {
                var q = vrt.query;
                q["Post"] = "N";
                router.push({ name: 'CIM_CM0122E', query: q });
            }
        }

        // 이벤트 핸들러
        const eventHandler = {
            dataTable: {
                // 행 클릭 이벤트
                onRowClick: (event) => {
                    var q = vrt.query;
                    q["Seq"] = event.data.Seq;
                    router.push({ name: 'CIM_CM0121D', query: q });
                }
            }
        };

        onMounted(() => {

            // cims 공지사항 초기조회
            methods.getCimsNotices();
        });

        return {
            state,
            cimsNotice,
            methods,
            eventHandler,
        }
    }
}
</script>